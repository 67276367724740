import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export const RejectModal = (props) => {
  const [additional, setAdditional] = useState("");
  const [showAdditionalError, setShowAdditionalError] = useState(false);

  const toggle = () => {
    props.setToggle(!props.toggle);
  };

  const submitted = async (e) => {
  
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }
    
    toggle();    
    props.onReject(props.accountId, additional);
  }

  const validate = () => {
    let isSuccess = true;

    if (!additional) {
      setShowAdditionalError(true);
      isSuccess = false;
    }
    
    return isSuccess;
  }

  return (
    <Modal isOpen={props.toggle} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Reject Account</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitted}>
          <FormGroup>
            <Label for="exampleEmail">Justification</Label>
            <Input
              type="textarea"
              name="additionalEmail"
              id="additionalEmail"
              placeholder="Justification text that will be included in email"
              onChange={(e) => setAdditional(e.target?.value)}
              value={additional}
            />
            {showAdditionalError && <p className="text-danger">Required</p>}
          </FormGroup>

          <Button color="success" className="mr-2 mt-3">
            Reject
          </Button>
          <Button 
              className="mt-3"
              color="danger"
              onClick={() => toggle()}
          > 
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};