import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "../pages/HomePage/HomePage";
import Page1 from "../pages/Page1";
import Page2 from "../pages/Page2";

import { AzureAD } from 'react-aad-msal';
import { authProvider } from '../../authProvider';

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"



const Main = () => (
  <div>
    <main>
      <Switch> 
        <AzureAD provider={authProvider} forceLogin={true}>
            <Route path="/accounts" component={Home} />
            <Route exact path="/">
                <Redirect to="/accounts" />
            </Route>
        </AzureAD>        
      </Switch>       
    </main>
  </div>
);

export default Main;
