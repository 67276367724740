import React, { useState, useEffect } from "react";
import { default as UUID } from "uuid";
import Alerts from "../../molecules/Alerts";
import moment from "moment";
import { AccountModal } from "./AccountModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import {
  getAccount,
  rejectLineManager,
  approveLineManager,
  getAccountsFromStatus,
} from "../../../utils/queries";
import "./HomePage.css";

import { Container, Input, Col, Row, Table } from "reactstrap";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

function isNil(value) {
  return value === undefined || value === null;
}

const Home = (props) => {
  const history = useHistory();
  const [alerts, setAlerts] = useState([]);
  const [nameFilter, setNameFilter] = useState("");

  // const [displayedAccountList, setDisplayedAccountList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [toggleAccountModal, setToggleAccountModalInternal] = useState(false);

  const setToggleAccountModal = (value) => {
    setToggleAccountModalInternal(value);
    if (value === false) history.push("/accounts");
  };

  useEffect(() => {
    loadAccounts().then();
  }, []);

  const addAlert = (message, color) => {
    let id = UUID.v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const searchChanged = (e) => {
    setNameFilter(e.target.value);
  };

  // const onLoad = async (giro) => {};

  // useEffect(() => {
  //   //displayAccountList(selectedStatus);

  //   // eslint-disable-next-line
  // }, [accountList]);

  // const displayAccountList = (status) => {
  //   if (status === null || status === "" || status === "Select status") {
  //     setDisplayedAccountList(accountList);
  //   }
  // };

  const onUpdateAccount = (account) => {
    // try {

    setAccountList(accountList.filter((el) => el.id !== account.id));

    // setToggleAccountModal(false);
    //addAlert("Account rejected successfully", "success");
    // } catch (error) {
    //   //addAlert(error, "danger");
    // }
  };

  const loadAccounts = async () => {
    try {
      //let result = await getSubmittedAccounts();
      let accounts = await trackPromise(
        getAccountsFromStatus("Validated by requestor")
      );

      var statusDistinct = accounts.data.map((s) => s.status);
      statusDistinct = [...new Set(statusDistinct)];
      statusDistinct.unshift("Select status");

      setAccountList(accounts.data);
    } catch (error) {
      addAlert(error, "danger");
    }
  };

  // const showAccountModal = async (account) => {
  //   try {
  //     let result = await getAccount(account.id);
  //     setSelectedAccount(result);
  //     setToggleAccountModal(!toggleAccountModal);
  //   } catch (error) {
  //     //addAlert(error, "danger");
  //   }
  // };

  // const showBankModal = async (account) => {

  //   try
  //   {
  //     let result = await getAccount(account.id);
  //     setSelectedAccount(result);
  //     setToggleBankModal(!toggleBankModal);
  //   }
  //   catch(error){
  //     addAlert(error, "danger");
  //   }

  // };
  const filterLogic = (account) => {
    let searchValue = parseInt(nameFilter, 10);
    if (isNaN(searchValue)) {
      return (
        !nameFilter ||
        account?.name?.toLowerCase().includes(nameFilter.toLowerCase()) ||
        account?.accountName
          ?.toLowerCase()
          .includes(nameFilter.toLowerCase()) ||
        account?.newAccountName
          ?.toLowerCase()
          .includes(nameFilter.toLowerCase()) ||
        account?.token?.toLowerCase() === nameFilter.toLowerCase()
      );
    }
    return Object.values(account).some((a) => String(a).includes(searchValue));
  };
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col>
            <Input
              type="search"
              name="search"
              id="search"
              placeholder="Enter name (minimum 2 characters) or account Id"
              onChange={searchChanged}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <Table bordered striped>
              <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "8%" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "auto" }} />
                <col span="1" style={{ width: "2%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Vendor bank details verbally confirmed</th>
                  <th>Existing account number</th>
                  <th>Existing account</th>
                  <th>New account name</th>
                  <th>Vendor name</th>
                  <th>Status</th>
                  <th>Submitted date</th>
                  <th>Requested by name</th>
                  <th>Business justification</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {accountList?.filter(filterLogic).map((account, index) => (
                  <tr key={account.id}>
                    <td>{account.id}</td>
                    <td>{account.verballyConfirmed ? "Yes" : "No"}</td>
                    <td>{account.accountNumber}</td>
                    <td>{account.accountName}</td>
                    <td>{account.newAccountName}</td>
                    <td>{account.name}</td>
                    <td>{account.status}</td>
                    <td>
                      {account.formSubmittedDate == null
                        ? ""
                        : moment(account.formSubmittedDate).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                    </td>
                    <td>{account.requestedByName}</td>
                    <td>{account.businessJustification}</td>
                    <td style={{ width: 90 }} className={"text-center"}>
                      <Link
                        className="btn btn-sm"
                        to={`${props.match.url}/edit/${account.id}`}
                      >
                        <FontAwesomeIcon icon={faList} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <br />

      <Route path={`${props.match.url}/edit/:id`} exact>
        <ShowAccountModal
          setToggleAccountModal={setToggleAccountModal}
          toggleAccountModal={toggleAccountModal}
          onUpdateAccount={onUpdateAccount}
        />
      </Route>
    </>
  );
};

const ShowAccountModal = ({
  setToggleAccountModal,
  toggleAccountModal,
  onUpdateAccount,
}) => {
  const { id } = useParams();

  return (
    <AccountModal
      id={id}
      toggle={toggleAccountModal}
      setToggle={setToggleAccountModal}
      onUpdateAccount={onUpdateAccount}
    />
  );
};

export default Home;
