import axios from 'axios'
import config from "../config";



const baseUrl = `${config.API_URL}`;

const  apiGet = async (url, token) => {
    
    return axios.get(`${config.API_URL}${url}`);
 };

export  const  getSubmittedAccounts = async () => {
    var url = `account/GetSubmittedAccounts`;
     return apiGet(url);
 };

 export  const  getAccount = async (id) => {
   var url = `account/${id}`;
    return apiGet(url);
};

export const rejectLineManager = async (id, additional) => {
    const data = {
        VFAccountId: parseInt(id, 10),
        rejectReason: additional,
    };
    return axios.post(`${baseUrl}account/rejectlinemanager/`, data);
};

export  const  approveLineManager = async (id) => {
   var url = `account/approvelinemanager/${id}`;
    return apiGet(url);
};

export  const  getAccountsFromStatus = async (status) => {
   var url = `account/getmyaccountsfromstatus/${status}`;
    return apiGet(url);
};

// export  const  setAccountsStatus = async (id, status) => {
//    var url = `account/setaccountstatus/${id}/${status}`;
//     return apiGet(url);
// };


  