import { MsalAuthProvider, LoginType } from "react-aad-msal";

var redirectUri = "http://localhost:4030";
var clientId = "d9b78d8b-78ae-43d4-b9ea-3fed1e225e1f";

if (process.env.REACT_APP_STAGE === "Dev") {
  redirectUri = "https://app-accounts-manager-dev.azurewebsites.net";
  clientId = "d9b78d8b-78ae-43d4-b9ea-3fed1e225e1f";
}
if (process.env.REACT_APP_STAGE === "Test") {
  redirectUri = "https://app-accounts-manager-tst.azurewebsites.net";
  clientId = "d9b78d8b-78ae-43d4-b9ea-3fed1e225e1f";
} else if (process.env.REACT_APP_STAGE === "Pre-Production") {
  redirectUri = "https://hafniabw-account-approval-line-pre.azurewebsites.net";
  clientId = "b5e9533d-cad1-4881-9cea-b1d554375fec";
} else if (process.env.REACT_APP_STAGE === "Production") {
  redirectUri = "https://accounts-manager.hafniabw.com";
  clientId = "b5e9533d-cad1-4881-9cea-b1d554375fec";
}

const msalConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/a364eb28-e95b-4ad0-a4fb-5b4f7767ad84",
    clientId: clientId,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const authParameters = {
  scopes: [
    "api://422132b5-d04c-4e43-a6db-6651f01a1109/Read", // API SCOPE
  ],
};

const authOptions = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  msalConfig,
  authParameters,
  authOptions
);
