import React from "react";

import {
    Alert,
  } from "reactstrap";

const Alerts = (props) => {
  
    

  const onDismissAlert = (a) => {
    props.setAlerts(props.alerts.filter((item) => item.id !== a.id));
  };





  return (
    <>
      {props.alerts
        .filter((alert) => alert.alertType === props.alertType)
        .map((alert, index) => (
          <Alert
            key={index}
            color={alert.color}
            toggle={() => onDismissAlert(alert)}
          >
            {alert.message}
          </Alert>
        ))}
    </>
  );
};

export default Alerts;